import React, { Component } from "react";
import { VscGraph } from "react-icons/vsc";
import { SiSpringsecurity } from "react-icons/si";
import { BsFillLockFill } from "react-icons/bs";
import { BsFillUnlockFill } from "react-icons/bs";
import Timer from "./Timer";

const MainFunction = () => {
    const startDate = new Date('2024-03-26T17:00:00Z');
    const currentDate = Date.now();
    const endDate = new Date('2025-03-26T17:00:00Z');
    let state;
    if (currentDate < startDate.getTime()) {
        state = "Project Launch In:";
    } else if (currentDate >= startDate.getTime() && currentDate <= endDate.getTime()){
        state = "";
    } else if (currentDate > endDate.getTime()) {
        state = "";
    }
    return (
        <div className="feature flex fade-in-bottom" id="mainFunction">
            <div className="feature-text flex-1 text-[18px] text-white">
                <div>
                    {/* <BsFillLockFill className="text-[#FFFFFF] inline-block mr-1 text-2xl mainFunction-img" />
                    <span className="text-[#FFFFFF]"> {state} </span>
                    <Timer /> */}
                    {/* <Countdown date={date} className="mytimer" /> */}
                    <BsFillUnlockFill className="text-[#FFFFFF] inline-block mr-1 text-2xl mainFunction-img" />
                    <span className="text-[#FFFFFF]"> Project is Live Now! </span>
                    {/* <Timer /> */}
                    <div className="usdc-value">
                    <span mr-4  className="text-[#ffbb35]">Mar 26th 5pm UTC</span>
                    </div>
                </div>
                <div className="mobile-hidden" style={{marginTop: "4rem"}}>
                    <VscGraph className="text-[#FFFFFF] inline-block mr-1 text-2xl mainFunction-img" />
                    <span className="text-[#FFFFFF]"> DCON Price: </span>
                    <div className="usdc-value">
                        <span className="text-[#50C6FB] mr-4">20</span><span className="text-[#ffbb35]"> USDC</span>
                    </div>
                </div>
                <div className="marginBottom mobile-hidden">
                    <SiSpringsecurity className="text-[#FFFFFF] inline-block mr-1 text-2xl mainFunction-img" />
                    <span className="text-[#FFFFFF]">Initial LP: </span>
                    <div className="usdc-value">
                        <span className="text-[#50C6FB] mr-4">7500 </span><span className="text-[#ffbb35]"> DCONs</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainFunction;
